/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap'); */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #a59c9c;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.logo_header {
  text-decoration: none;
}

.img_logo_header {
  width: 153px;
  height: auto;
}

.navigation_header {
  display: flex;
  align-items: center;
}

.navigation_header a {
  text-decoration: none;
  color: #333;
  margin-right: 10px;
  padding: 5px;
}

.navigation_header a.active {
  font-weight: bold;
}

.user {
  width: 40px;
  height: auto;
  margin-left: 10px;
}

.header__exit {
  border: none;
  background: none;
  cursor: pointer;
}

.header__exit img {
  margin-left: 50px;
  width: 40px;
  height: 40px;
}

.tours {
  border-right: 1px solid #ccc;
  padding-right: 10px;
}

.courses {
  padding-left: 10px;
}

.active_header {
  display: inline-block;
  font-weight: bold;
  background-color: #6b616186;
  border-radius: 4px;
  padding: 9px;
  text-decoration: underline;
}

.tours img {
  background-color: #907b3c95;
  padding: 2px;
  border-radius: 5px;
}
.courses img {
  background-color: #5777778f;
  padding: 2px;
  border-radius: 5px;
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
nav a,
nav span {
  text-decoration: none;
  display: block;
  color: #222;
  /* border: 1px dotted #000;
  border-radius: 10px; */
  margin: 0 4px;
}

.topmenu > li {
  display: inline-block;
  position: relative;
  margin: 0 8px;
}

.topmenu > li > a,
.topmenu > li > span {
  position: relative;
  padding: 10px 15px;
  font-size: 1.3em;
  line-height: 1;
  letter-spacing: 1px;
  font-size: 18px;
  font-family: "Kaushan Script", cursive;
  font-size: 1.4em;
}

.topmenu > li > a:before {
  content: "";
  position: absolute;
  z-index: 5;
  left: 50%;
  top: 100%;
  width: 10px;
  height: 10px;
  background: white;
  border-radius: 50%;
  transform: translate(-50%, 20px);
  opacity: 0;
  transition: 0.3s;
}
.topmenu li:hover a:before {
  transform: translate(-50%, 0);
  opacity: 1;
}
.submenu {
  position: absolute;
  z-index: 4;
  left: 50%;
  top: 100%;
  width: 250px;
  padding: 15px 0 15px;
  margin-top: 5px;
  background: white;
  border-radius: 5px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  visibility: hidden;
  opacity: 0;
  transform: translate(-50%, 20px);
  transition: 0.3s;
}
.topmenu > li:hover .submenu {
  visibility: visible;
  opacity: 1;
  transform: translate(-50%, 0);
}
.submenu a {
  font-family: "Libre Baskerville", serif;
  font-size: 18px;
  letter-spacing: 1px;
  padding: 5px 10px;
  transition: 0.3s linear;
}

.submenu li:nth-child(odd) a {
  background-color: #f2f0f0bb;
}

.submenu li:hover a {
  background: #dedddd;
}
