.container__main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
}

.profile {
  width: 500px;
  background-color: var(--color-dark5);
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.profile__frame {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 30px;
}

.profile__picture {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-right: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.profile__name {
  font-size: 2rem;
  margin: 0;
  color: var(--color-dark4);
}

.profile__info {
  margin-top: 30px;
}

.form-label {
  font-size: 1rem;
  margin-bottom: 10px;
  color: var(--color-dark5);
}

.profile__input {
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  border: 1px solid var(--color-dark3-header);
  border-radius: 5px;
  margin-bottom: 20px;
}

.profile__button {
  width: 100%;
  padding: 12px;
  background-color: var(--color-dark4);
  color: var(--color-white);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.profile__button:hover {
  background-color: var(--color-dark3-header-active-hover);
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading h1 {
  font-size: 2rem;
  color: var(--color-dark5);
}
