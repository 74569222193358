:root {
  --input-background-color: #f9f2f2c3;
  --btn-background-color: #efdddddf;
  --h1-color: #fcf7f7;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.navbar .nav-btn {
  width: 24px;
  height: 24px;
  border: none;
  border-radius: 100%;
  cursor: pointer;
  margin: 10px;
}

.container {
  width: 420px;
  color: #fff;
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(5px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 30px 40px;
  border-radius: 10px;
}

.container h1 {
  font-size: 36px;
  text-align: center;
  color: var(--h1-color);
}

.container .input_box {
  width: 100%;
  height: 50px;
  margin: 30px 0;
}

.container input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 40px;
  font-size: 16px;
  color: #333;
  padding: 20px 45px 20px 20px;
  background-color: var(--input-background-color);
}

.input_box input::placeholder {
  color: #333;
}

.container .btn {
  width: 100%;
  height: 45px;
  background-color: var(--btn-background-color);
  border: none;
  outline: none;
  border-radius: 40px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  font-size: 16px;
  color: #333;
  font-weight: 600;
  margin: 12px 0;
}

.form_group {
  margin-top: 10px;
}

.form_group__a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
}

.form_group__a:hover {
  text-decoration: underline;
}
