.create_article__container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 24px;
}

.create_article__title {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 24px;
}

.create_article_category {
  margin-bottom: 24px;
}

.create_article__loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.create_article__loading h1 {
  font-size: 24px;
  font-weight: bold;
}

.categories__label {
  display: block;
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: 600;
}

.categories__select {
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 8px;
  padding: 0 16px;
  font-size: 16px;
  background-color: #f2f2f2;
  color: #333;
}

.categoryLink {
  color: #333;
}

.editor__field {
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 8px;
  padding: 0 16px;
  font-size: 16px;
  margin-bottom: 24px;
  background-color: #f2f2f2;
  color: #333;
}

.editor__container {
  border-radius: 8px;
  border: 1px solid #ccc;
  padding: 8px;
  background-color: #fff;
}

.rte-container {
  height: 400px;
}

.ql-toolbar {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: none;
  background-color: #fff;
}

.ql-container {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top: none;
  background-color: #fff;
}

.categories__button {
  display: block;
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
  height: 40px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  background-color: var(--color-blue);
  color: #fff;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin-top: 20px;
}

.categories__button:hover {
  background-color: #7f8c99;
}
