.container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

.container_left {
  max-width: 700px;
  margin: 0 auto;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #5656567d;
}

.container_right {
  min-width: 1000px;
  min-height: 500px;
}

.cardImage {
  width: 100%;
  max-height: 300px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 20px;
}

.title {
  font-size: 32px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.description {
  font-size: 18px;
  color: #555;
  line-height: 1.6;
  margin-bottom: 20px;
  border-left: 4px solid #7d9daa;
  padding-left: 10px;
}

.place,
.address {
  font-size: 17px;
  color: #777;
  margin-bottom: 10px;
}

.place::before,
.address::before {
  content: "•";
  margin-right: 6px;
}

.rating_container__div {
  margin-top: 20px;
}

.rating_userStars__p {
  color: #fff;
  font-size: 22px;
  margin-bottom: 5px;
}

.rating {
  margin-top: 30px;
  color: #333;
}

.categoryTitle {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.addCategoryButton {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background-color: var(--color-blue);
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 20px;
}

.addCategoryButton:hover {
  background-color: #0056b3;
}

.tagInput,
.categoryInput {
  flex: 1;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 20px 0 0 20px;
}

.deleteCategoryButton {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background-color: #dc3545;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.deleteCategoryButton:hover {
  background-color: #c82333;
}

.tags {
  margin-top: 30px;
  white-space: nowrap;
  overflow-x: auto;
}

.tagItem {
  display: inline-flex;
  align-items: center;
  background-color: var(--color-blue);
  color: #fff;
  border-radius: 20px;
  padding: 8px 12px;
  font-size: 14px;
  margin-right: 8px;
  margin-bottom: 8px;
}

.tagItem:hover {
  background-color: var(--color-blue);
}

.tagInputContainer {
  display: flex;
  align-items: center;
}

.addButton {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background-color: var(--color-blue);
  border: none;
  border-radius: 0 20px 20px 0;
  cursor: pointer;
  transition: background-color 0.3s;
}

.addButton:hover {
  background-color: #7f8c99;
}

.deleteButton {
  background: none;
  border: none;
  padding: 0;
  margin-left: 4px;
  cursor: pointer;
  transition: opacity 0.3s;
}

.deleteButton img {
  margin-left: 4px;
  width: 28px;
  height: 28px;
}

.deleteButton:hover {
  opacity: 0.7;
}

.categoryInputContainer {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.setCategoryButton {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background-color: var(--color-blue);
  border: none;
  border-radius: 0 20px 20px 0;
  cursor: pointer;
  transition: background-color 0.3s;
}

.setCategoryButton:hover {
  background-color: #7f8c99;
}

.cardTitle {
  background-color: #c8d2dc;
  color: #fff;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.cardTitle .title {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 10px;
}

.cardTitle .description {
  font-size: 18px;
  margin-bottom: 10px;
}

.cardTitle .place,
.cardTitle .address {
  font-size: 17.5px;
  margin-bottom: 5px;
}

.category {
  display: inline-flex;
  align-items: center;
  background-color: #8697a8;
  color: #fff;
  padding: 9px 12px;
  border-radius: 20px;
  margin-right: 8px;
  font-size: 14px;
  font-weight: bold;
}

.category:hover {
  background-color: #7e9b9f;
}

.category .deleteButton {
  margin-left: 8px;
  transition: transform 0.3s;
}

.deleteButton:hover {
  transform: scale(1.1);
}

.comments {
  margin-top: 20px;
  color: white;
}

.commentsTitle {
  font-size: 18px;
  margin-bottom: 10px;
}

.comment {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #5656568f;
  color: white;
}

.commentUser {
  font-weight: bold;
}

.commentText {
  margin-top: 5px;
}

.commentForm {
  display: flex;
  align-items: center;
}

.commentInput {
  flex: 1;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 20px;
  margin-right: 10px;
  transition: border-color 0.3s;
}

.commentButton {
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #8697a8;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.commentButton:hover {
  background-color: #7f8c99;
}

.setComments {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background-color: var(--color-blue);
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 20px;
  margin-left: 0;
}

.setComments:hover {
  background-color: #7f8c99;
}

@keyframes centerAnimation {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0%);
  }
}

.toggleButton {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: #fff;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  animation: centerAnimation 0.5s ease-out;
}

.toggleButton:hover {
  background-color: #8697a8;
}

.container .toggleButton {
  margin: 20px auto;
  display: block;
}

.container_right .toggleButton {
  margin: 20px auto;
  display: block;
}

.toggleButton.animated {
  animation: pulse 3s infinite;
}

.toggleButton_return {
  background-color: transparent;
  border: none;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  margin-top: 6px;
  border-radius: 50%;
}

.toggleButton_return img {
  width: 50px;
  height: 50px;
}

.toggleButton_return:hover {
  background-color: #cccccc78;
  border-radius: 50%;
}





