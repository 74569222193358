:root {
  --color-white: #fff;
  --color-dark1: rgb(131, 69, 69);
  --color-dark3-header: #98847e;
  --color-dark3-header-active: #786560be;
  --color-dark3-header-active-hover: #5c3e36be;
  --color-dark4: #272727;
  --color-dark5: #ad8c8c;
  --color-blue: #7d9daa;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  height: 100vh; 
  min-width: 1500px;
  background: url("./asserts/images/bg.jpg") no-repeat center / cover;
  font-family: Arial, Helvetica, sans-serif;
  background-attachment: fixed;
}
