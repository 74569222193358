.profile_light {
  background-color: rgba(179, 182, 184, 0.844);
  margin: 0 auto;
  width: 75%;
}
.profile_light_data {
  background: linear-gradient(
    rgba(138, 169, 183, 0.06),
    rgba(255, 255, 255, 0.2)
  );
  border-radius: 10px;
  backdrop-filter: blur(10px);
}

.profile_button {
  margin-left: 5px;
  width: 100%;
  padding: 12px;
  background-color: var(--color-dark5);
  color: var(--color-white);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.profile_button:hover {
  width: 100%;
  padding: 12px;
  background-color: var(--color-dark3-header-active-hover);
  color: var(--color-white);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}
