.container {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.card {
  width: calc(24% - 10px);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  background: linear-gradient(rgb(83, 140, 167), rgba(222, 186, 186, 0.901));
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.card:hover {
  transform: translateY(-5px);
}

.cardImage {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.placeholderImage {
  width: 100%;
  height: 150px;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.placeholderText {
  color: #666;
  font-size: 16px;
}

.cardContent {
  padding: 15px;
}

.cardContent h2 {
  margin: 0;
  font-size: 25px;
  margin-bottom: 10px;
  color: #fff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.cardContent p {
  margin: 0;
  margin-bottom: 8px;
  color: #fff;
  line-height: 1.4;
}

.cardContent strong {
  font-weight: bold;
  color: #fff;
}

.button {
  display: inline-block;
  padding: 10px 20px;
  background-color: var(--color-dark3-header-active);
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: var(--color-dark3-header-active-hover);
}

.cardImageContainer {
  position: relative;
}

.cardImage {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.placeholderImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200px;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.placeholderText {
  color: #666;
  font-size: 16px;
}

.bookmark_btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease;
  z-index: 1;
}

.bookmarked .bookmarkIcon {
  display: block;
}

.bookmarkIcon,
.addedBookmarkIcon {
  width: 40px;
  height: 40px;
}

.cardContent {
  padding: 15px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.cardContentData {
  flex-grow: 1;
}

.cardContentLink {
  margin-top: auto;
}
