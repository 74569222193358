.heading {
  font-size: 25px;
  margin-right: 25px;
}

.row{
  margin-left: 10px;
}

.fa {
  font-size: 25px;
}

.checked {
  color: orange;
}

.side {
  float: left;
  width: 15%;
  margin-top: 10px;
  padding: 0;
}

.sss {
  margin-right: 190px;
  padding: 0;
}

.ddd {
  display: inline-block;
  font-size: 19px;
}

.middle {
  float: left;
  width: 70%;
  margin-top: 10px;
  padding: 0;
}

.right {
  text-align: right;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.bar_container {
  width: 100%;
  background-color: #f1f1f1;
  text-align: center;
  color: white;
  margin-left: -20px;
}

.bar-5 {
  height: 22px;
  background-color: #2e7d32;
  width: var(--width);
}

.bar-4 {
  height: 22px;
  background-color: #4caf50;
  width: var(--width);
}

.bar-3 {
  height: 22px;
  background-color: #ffc107;
  width: var(--width);
}

.bar-2 {
  height: 22px;
  background-color: #ff9800;
  width: var(--width);
}

.bar-1 {
  height: 22px;
  background-color: #ff5722;
  width: var(--width);
}

.hr_user__rating {
  border: 3px solid #f1f1f1;
}

@media (max-width: 400px) {
  .side,
  .middle {
    width: 100%;
  }
  .right {
    display: none;
  }
}
