.categoriesContainer {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background: linear-gradient(rgb(83, 140, 167), rgba(255, 218, 218, 0.829));
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.5s ease forwards;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .categoryList {
    list-style-type: none;
    padding: 0;
  }
  
  .categoryItem {
    margin-bottom: 20px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .categoryItem:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
  
  .categoryLink {
    display: block;
    padding: 20px;
    background-color: #f5f0f0cd;
    color: #333;
    text-decoration: none;
    transition: background-color 0.3s ease, color 0.3s ease;
    border-radius: 10px;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }
  
  .categoryLink:hover {
    background-color: #e6e6e6;
    filter: brightness(90%);
  }
  
  h2 {
    font-size: 36px;
    color: #333;
    text-align: center;
    margin-bottom: 30px;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  